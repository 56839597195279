import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Wrapper from '../wrapper'
import { typography, Media } from '../../theme'
import Button from '../button'
import Toast from '../toast'
import {
  verifyCodeType,
  uploadFile,
  sendVerifyCode,
  agentApply,
  verifyPhone,
} from '../../service/agency'

const AgencyFormContainer = styled.div`
  background: #ffffff;
  width: 600px;
  padding: 0 50px;
  ${Media.lessThan(Media.small)} {
    padding: 76px 0;
    width: 100%;
  }
`
const AgencyFormWrap = styled(Wrapper)`
  width: 100%;
  .title {
    color: #000000;
    font-size: ${typography.h3};
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 23px;
    text-align: center;
  }
  button {
    cursor: pointer;
    height: 40px;
    background: #6b6cf9;
    border-radius: 6px;
    min-width: 110px;
    font-size: ${typography.textSmall};
    color: #ffffff;
    line-height: 24px;
  }
  label {
    width: 81px;
    font-size: ${typography.textSmall};
    text-align: end;
    margin-right: 10px;
    line-height: 40px;
    ::before {
      content: '*';
      color: #e25c59;
    }
    &.no-require {
      ::before {
        display: none;
      }
    }
  }
  .item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 22px;
    position: relative;
  }
  .input {
    height: 40px;
    outline-style: none;
    border: 1px solid #dddddd;
    border-radius: 6px;
    padding: 12px 9px;
    font-size: ${typography.textSmall};
    min-width: 400px;
    &.short {
      min-width: 160px;
      width: 160px;
    }
    &.upload {
      min-width: 100px;
      height: 100px;
      width: 100px;
      margin-right: 300px;
      opacity: 0;
      cursor: pointer;
      z-index: 2;
    }
    &.error-border {
      border: 1px solid #ee344a;
    }
  }
  .upload-placeholder {
    font-size: 14px;
    height: 100px;
    width: 100px;
    border: 1px solid #dddddd;
    border-radius: 6px;
    position: absolute;
    left: 91px;
    background-repeat: no-repeat;
    background-size: auto 100px;
    ::before {
      content: ' ';
      width: 30px;
      height: 2px;
      background: #000000;
      top: 49px;
      left: 34px;
      position: absolute;
    }
    ::after {
      content: ' ';
      height: 30px;
      width: 2px;
      background: #000000;
      left: 49px;
      top: 34px;
      position: absolute;
    }
    &.error-border {
      border: 1px solid #ee344a;
    }
  }
  .pic-tip {
    position: absolute;
    top: 80px;
    left: 200px;
    color: #999999;
    font-size: ${typography.textSmall};
  }
  .textarea {
    resize: none;
    height: 120px;
  }
  .line {
    display: flex;
    button {
      margin-left: 10px;
    }
  }
  .error {
    position: absolute;
    font-size: ${typography.textThin};
    color: #ee344a;
    left: 91px;
    bottom: -18px;
  }
  .footer-line {
    margin-left: 88px;
    .sub-btn {
      border: 1px solid #675ffe;
      border-radius: 6px;
      color: #675ffe;
      background: transparent;
      margin-left: 10px;
    }
  }
  ${Media.lessThan(Media.small)} {
    border-radius: 10px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .title {
      font-size: ${typography.h3};
      margin: auto;
    }
    form {
      padding: 20px 26px 0 20px;
      width: 100%;
    }
    button {
      margin: 0 auto;
      width: 100%;
      min-width: 100px;
    }
    .item {
      flex-flow: column;
      label {
        text-align: start;
        line-height: 20px;
      }
    }
    .line {
      label {
        display: none;
      }
      button {
        min-width: 75px;
        width: 100px;
        margin: 0 0 0 8px;
      }
      .input {
        flex: 1;
      }
    }
    .error {
      left: 0;
    }
    .input {
      min-width: 230px;
      &.short {
        min-width: 95px;
        width: 120px;
      }
    }
    .upload-placeholder {
      left: 0;
      top: 20px;
    }
    .pic-tip {
      top: 50px;
      left: 110px;
    }
    .footer-line {
      margin-left: 0;
      .sub-btn {
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }
`

class AgencyForm extends PureComponent {
  state = {
    name: '',
    phone: '',
    code: '',
    email: '',
    company: '',
    pic: '',
    mark: '',
  }
  restartState = () => {
    this.setState({
      name: '',
      phone: '',
      code: '',
      email: '',
      company: '',
      pic: '',
      mark: '',
    })
    document.getElementById('upload-placeholder').style.backgroundImage = ''
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit = async event => {
    event.preventDefault()
    const phoneResult = await this.checkPhoneNumber()
    if (
      !this.checkName() ||
      !phoneResult ||
      !this.checkCode() ||
      !this.checkEmail() ||
      !this.checkCompany()
    )
      return
    const { name, phone, code, email, company, pic, mark } = this.state
    agentApply(name, phone, code, email, company, pic, mark)
      .then(resp => {
        if (resp.data.code === 200) {
          Toast.info('提交成功，请耐心等待审核')
          this.restartState()
          this.props.isOpen(false)
        } else if (resp.data.code === 604) {
          Toast.info('验证码不正确')
        } else {
          Toast.info('提交失败，请稍后重试！')
        }
      })
      .catch(() => {
        Toast.info('提交失败，请稍后重试！')
      })
  }

  isVerifyPhone = async () => {
    const code = await verifyPhone(this.state.phone)
      .then(resp => {
        return resp.data.code
      })
      .catch(() => {
        return 0
      })
    return code
  }

  handleUploadFile = event => {
    const file = event.target.files[0]
    if (!file) return
    const nameType = ['jpeg', 'jpg', 'png', 'tga', 'mpeg']
    const fileName = file.name.split('.')?.pop()
    const isNameType = fileName && nameType.includes(fileName.toLocaleLowerCase())
    const isLegitimate = file.size / 1024 / 1024 < 100

    const parentNode = this.picRef.parentNode
    const error = document.createElement('div')
    error.classList.add('error')
    if (!isNameType) {
      error.innerText = '格式不正确'
    } else if (!isLegitimate) {
      error.innerText = '最大上传100M'
    } else {
      this.picRef.classList.remove('error-border')
      const errors = parentNode.querySelectorAll('.error')
      errors.forEach(child => parentNode.removeChild(child))

      const formData = new FormData()
      formData.append('file', file)
      uploadFile(formData)
        .then(resp => {
          if (resp.data.code !== 200) {
            Toast.info('上传失败，请稍后重试！')
          } else {
            document.getElementById(
              'upload-placeholder',
            ).style.backgroundImage = `url(${resp.data.data})`
            this.setState({ pic: resp.data.data })
          }
        })
        .catch(() => {
          Toast.info('上传失败，请稍后重试！')
        })
      return
    }
    const errors = parentNode.querySelectorAll('.error')
    errors.forEach(child => parentNode.removeChild(child))

    this.picRef.classList.add('error-border')

    parentNode.appendChild(error)
  }

  handleGetCode = async type => {
    const { phone } = this.state
    const result = await this.checkPhoneNumber()
    if (!result) {
      return
    }
    sendVerifyCode(phone, type)
      .then(resp => {
        if (resp.data.code === 200) {
          Toast.info(`发送成功！请注意查收，剩余${resp.data.data.remainCount}次`)
        } else if (resp.data.code === 823) {
          Toast.info('验证码次数超过5次，请您24H后重新验证。')
        } else if (resp.data.code === 1051) {
          Toast.info('验证码在有限期内，请稍后再试')
        } else {
          Toast.info('获取失败，请稍后重试！')
        }
      })
      .catch(() => {
        Toast.info('获取失败，请稍后重试！')
      })
  }

  checkName = () => {
    const { name } = this.state
    const parentNode = this.nameRef.parentNode
    const error = document.createElement('div')
    error.classList.add('error')
    if (name === '') {
      error.innerText = '姓名不能为空'
    } else if (name.length > 50) {
      error.innerText = '姓名不可超过50个字符'
    } else {
      this.nameRef.classList.remove('error-border')
      const errors = parentNode.querySelectorAll('.error')
      errors.forEach(child => parentNode.removeChild(child))
      return true
    }
    const errors = parentNode.querySelectorAll('.error')
    errors.forEach(child => parentNode.removeChild(child))

    this.nameRef.classList.add('error-border')

    parentNode.appendChild(error)
    return false
  }

  checkPhoneNumber = async () => {
    const { phone } = this.state
    const parentNode = this.phoneRef.parentNode
    const error = document.createElement('div')
    error.classList.add('error')
    if (phone === '') {
      error.innerText = '手机号不能为空'
    } else if (!/^1[0-9]{10}$/.test(phone)) {
      error.innerText = '请输入正确手机号'
    }
    if (error.innerText === '') {
      const result = await this.isVerifyPhone()
      if (result === 200) {
        this.phoneRef.classList.remove('error-border')
        const errors = parentNode.querySelectorAll('.error')
        errors.forEach(child => parentNode.removeChild(child))
        return true
      } else {
        switch (result) {
          case 900:
            error.innerText = '该手机号已被申请'
            break
          default:
            error.innerText = '手机号验证失败，请重新输入'
            break
        }
        const errors = parentNode.querySelectorAll('.error')
        errors.forEach(child => parentNode.removeChild(child))

        this.phoneRef.classList.add('error-border')

        parentNode.appendChild(error)
        return false
      }
    } else {
      const errors = parentNode.querySelectorAll('.error')
      errors.forEach(child => parentNode.removeChild(child))

      this.phoneRef.classList.add('error-border')

      parentNode.appendChild(error)
      return false
    }
  }

  checkCode = () => {
    const { code } = this.state
    const parentNode = this.codeRef.parentNode.parentNode
    const error = document.createElement('div')
    error.classList.add('error')
    if (code === '') {
      error.innerText = '验证码不能为空'
    } else if (code.length !== 6) {
      error.innerText = '请输入正确验证码'
    } else {
      this.codeRef.classList.remove('error-border')
      const errors = parentNode.querySelectorAll('.error')
      errors.forEach(child => parentNode.removeChild(child))
      return true
    }
    const errors = parentNode.querySelectorAll('.error')
    errors.forEach(child => parentNode.removeChild(child))

    this.codeRef.classList.add('error-border')

    parentNode.appendChild(error)
    return false
  }

  checkEmail = () => {
    const { email } = this.state
    const parentNode = this.emailRef.parentNode
    const error = document.createElement('div')
    error.classList.add('error')
    if (email === '') {
      error.innerText = '邮箱不能为空'
    } else if (!/(?=^.{6,40}$)^[-\w]+(\.[-\w]+)*@[-\w]+(\.[-\w]+)+$/.test(email)) {
      error.innerText = '请输入正确邮箱'
    } else {
      this.emailRef.classList.remove('error-border')
      const errors = parentNode.querySelectorAll('.error')
      errors.forEach(child => parentNode.removeChild(child))
      return true
    }
    const errors = parentNode.querySelectorAll('.error')
    errors.forEach(child => parentNode.removeChild(child))

    this.emailRef.classList.add('error-border')

    parentNode.appendChild(error)
    return false
  }

  checkCompany = () => {
    const { company } = this.state
    const parentNode = this.companyRef.parentNode
    const error = document.createElement('div')
    error.classList.add('error')
    if (company === '') {
      error.innerText = '公司不能为空'
    } else if (company.length > 50) {
      error.innerText = '公司名不可超过50个字符'
    } else {
      this.companyRef.classList.remove('error-border')
      const errors = parentNode.querySelectorAll('.error')
      errors.forEach(child => parentNode.removeChild(child))
      return true
    }
    const errors = parentNode.querySelectorAll('.error')
    errors.forEach(child => parentNode.removeChild(child))

    this.companyRef.classList.add('error-border')

    parentNode.appendChild(error)
    return false
  }

  render() {
    const { name, phone, code, email, company, mark } = this.state
    return (
      <AgencyFormContainer>
        <AgencyFormWrap>
          <div className='title'>大雁云代理合作申请书</div>
          <form onSubmit={this.handleSubmit} autoComplete='off' id={'agency-form'}>
            <div className='item'>
              <label>姓名:</label>
              <input
                ref={elem => (this.nameRef = elem)}
                type='text'
                name='name'
                value={name}
                onChange={this.handleInputChange}
                onBlur={this.checkName}
                className='input info'
                placeholder='请输入您的姓名'
              />
            </div>
            <div className='item'>
              <label>手机号:</label>
              <input
                ref={elem => (this.phoneRef = elem)}
                type='text'
                name='phone'
                value={phone}
                onChange={this.handleInputChange}
                onBlur={this.checkPhoneNumber}
                className='input info'
                placeholder='请输入您的手机号'
              />
            </div>
            <div className='item'>
              <div className='line'>
                <label className='no-require'> </label>
                <input
                  ref={elem => (this.codeRef = elem)}
                  type='text'
                  name='code'
                  value={code}
                  onChange={this.handleInputChange}
                  onBlur={this.checkCode}
                  className='input info short'
                  placeholder='请输入验证码'
                />
                <button type='button' onClick={() => this.handleGetCode(verifyCodeType.text)}>
                  短信验证码
                </button>
                <button type='button' onClick={() => this.handleGetCode(verifyCodeType.sound)}>
                  语音验证码
                </button>
              </div>
            </div>
            <div className='item'>
              <label>邮箱:</label>
              <input
                ref={elem => (this.emailRef = elem)}
                type='text'
                name='email'
                value={email}
                onChange={this.handleInputChange}
                onBlur={this.checkEmail}
                className='input info'
                placeholder='请输入您的邮箱'
              />
            </div>
            <div className='item'>
              <label>公司:</label>
              <input
                ref={elem => (this.companyRef = elem)}
                type='text'
                name='company'
                value={company}
                onChange={this.handleInputChange}
                onBlur={this.checkCompany}
                className='input info'
                placeholder='请输入您的公司名称'
              />
            </div>
            <div className='item'>
              <label className='no-require'>营业照上传:</label>
              <input type='file' onChange={this.handleUploadFile} className='input info upload' />
              <div
                ref={elem => (this.picRef = elem)}
                className='upload-placeholder'
                id='upload-placeholder'
              ></div>
              <div className='pic-tip'>注意不要上传水印，非彩色，模糊的营业照</div>
            </div>
            <div className='item'>
              <label className='no-require'>申请说明:</label>
              <textarea
                ref={elem => (this.markRef = elem)}
                className='input textarea'
                name='mark'
                onChange={this.handleInputChange}
                placeholder='填写您在当地的优势和资源'
                maxLength='200'
                value={mark}
              />
            </div>
            <div className='footer-line'>
              <Button type='submit' id='ljjr-tj'>
                提交
              </Button>
              <Button type='button' className='sub-btn' onClick={() => this.props.isOpen(false)}>
                取消
              </Button>
            </div>
          </form>
        </AgencyFormWrap>
      </AgencyFormContainer>
    )
  }
}

export default AgencyForm
