const RecordOfficialWebsiteEntry = () => {
  const date = new Date()
  date.setDate(date.getDate() + 1)
  document.cookie = `officialWebsiteEntry=${document.URL};expires=${date};path=/;domain=dayancloud.com;secure=1`
}

export const onAuthEntry = url => {
  RecordOfficialWebsiteEntry()
  window.location.href = url
}
