import styled from 'styled-components'
import { color, spacing, typography, transition } from '../../../theme'

export const SearchContainer = styled.div`
  max-width: 400px;
  display: flex;
`
export const SearchInput = styled.input`
  width: 79%;
  height: 40px;
  border: 1px solid ${color.text};
  text-indent: ${spacing.small};
`
export const SearchButton = styled.div`
  padding: 5px ${spacing.small};
  line-height: 30px;
  background-color: ${color.primary};
  margin-left: 5px;
  color: white;
  font-size: ${typography.h4};
  transition: ${transition('background-color')};
  cursor: pointer;
  :hover {
    background-color: ${color.primaryDark};
  }
`
export const SupportSearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 660px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid ${color.divider};
  input {
    outline: none;
    border: 0px;
    width: 90%;
    color: ${color.input};
    padding-left: ${spacing.base};
    font-size: ${typography.textSmall};
    background-color: transparent;
  }
`
export const SupportInstantSearchContainer = styled(SupportSearchContainer)`
  width: 300px;
  border-color: #ccc;
  background-color: white;
  input {
    width: 88%;
  }
  svg {
    color: ${color.primary};
  }
`
export const QaSearchContainer = styled(SupportSearchContainer)`
  max-width: 1000px;
  width: 100%;
  border-color: #ccc;
  background-color: white;
  input {
    flex: 1;
  }
  > div {
    margin-right: 10px;
  }
  svg {
    color: ${color.primary};
  }
`

export const SupportSearchButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  svg {
    color: ${color.text};
    transition: ${transition(['color'])};
  }
  :hover svg {
    color: ${color.primary};
  }
`
export const SupportInstantSearchButton = styled(SupportSearchButton)`
  svg {
    color: ${color.primary};
  }
`
