import React, { Component } from 'react'

import Item from './item'
import { BreadcrumbContainer } from './atoms'

class Breadcrumb extends Component {
  static Item = Item
  render() {
    const { separator, children } = this.props
    return <BreadcrumbContainer separator={separator}>{children}</BreadcrumbContainer>
  }
}
export default Breadcrumb
