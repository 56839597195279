import styled, { css } from 'styled-components'

import NavigationLink from '../header/navigation-link'
import Flex from '../flex'
import { Column } from '../grid'
import { Anchor } from '../atoms'
import { textEllipsis, transition, Media } from '../../theme'
import { typography, spacing, color } from '../../theme/variables'

export const Container = styled.footer`
  color: rgba(255, 255, 255, 0.4);
  background-color: #1f2023;
  a {
    font-size: ${typography.textSmall};
  }
`
export const PartnerWrap = styled(Flex)`
  font-size: ${typography.textThin};
  padding: 13px 0 21px;
  color: rgba(255, 255, 255, 0.2) !important;
  > p {
    margin: 0;
  }
  ${Media.lessThan(Media.small)} {
    padding: 18px 0 16px;
    flex-wrap: wrap;
    justify-content: center;
  }
`
export const FriendLinkWrap = styled.div`
  line-height: 27px;
  a {
    display: inline-block;
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.2) !important;
    font-size: ${typography.textThin};
    &:hover {
      color: rgba(255, 255, 255, 0.7) !important;
    }
  }
`

export const ProductLinkWrap = styled.div`
  height: 60px;
  line-height: 60px;
  a {
    display: inline-block;
    margin-right: 45px;
    color: rgba(255, 255, 255, 0.4) !important;
    &:hover {
      color: rgba(255, 255, 255, 0.7) !important;
    }
  }
  .productTitle {
    display: inline-block;
    margin-right: 47px;
    font-size: 0.875rem;
  }
  ${Media.lessThan(Media.small)} {
    height: auto;
    line-height: 22px;
    padding: 18px 5px;
    a {
      margin-right: 22px;
      font-size: ${typography.textSmall};
    }
    .productTitle {
      margin-right: 21px;
      margin-bottom: 5px;
    }
  }
`

export const PostCardsContainer = styled.div`
  padding: 64px 0 45px 0;
  font-size: ${typography.textSmall};
  ${Media.lessThan(Media.small)} {
    padding: 30px 5px 39px;
    text-align: center;
    > div > div:nth-child(2) {
      text-align: left;
      p,
      a {
        padding-left: 25px;
      }
    }
    .footer-news {
      display: none;
    }
  }
`
export const DeusColumn = styled(Column)`
  ${Media.lessThan(Media.small)} {
    text-align: left;
    flex-basis: auto;
    width: 50%;
    max-width: 100%;
  }
`

export const ContactColumn = styled(Column)`
  text-align: left;
  line-height: 2;
  ${Media.lessThan(Media.small)} {
    margin-top: 40px;
    max-width: 100%;
    flex-basis: auto;
  }
`

export const Deus = styled(Column)`
  ${Media.lessThan(Media.small)} {
    display: none;
    text-align: left;
  }
`

export const FollowColumn = styled(Column)`
  text-align: center;
  .wechat-number {
    text-align: center;
    margin-top: 10px;
  }
  ${Media.lessThan(Media.small)} {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    right: 18px;
    max-width: 100%;
  }
`

export const VerticalDivider = styled.div`
  background-color: #333333;
  width: 1px;
  height: 100%;
  margin: 0 auto;
`
export const Title = styled.p`
  color: white;
  line-height: 2;
  margin-top: initial;
  font-size: ${typography.text};
  margin-bottom: ${spacing.small};
  display: ${props => props.display || ''};
  margin-right: ${props => props.right || ''};
  &.hide-at-mobile {
    ${Media.lessThan(Media.small)} {
      visibility: hidden;
    }
  }
`

export const Follow = styled.p`
  text-align: center;
  color: white;
  line-height: 2;
  margin-top: initial;
  font-size: ${typography.text};
  margin-bottom: ${spacing.small};
`

export const BlockAnchor = styled(Anchor)`
  display: block;
  line-height: 2;
  font-size: ${typography.textSmall} !important;
  :hover span {
    color: #7d829c;
  }
`
const LinkStyle = css`
  cursor: pointer;
  color: currentColor;
  text-decoration: none;
  transition: ${transition('color')};
  padding: 0;
  :hover {
    color: ${color.primary};
  }
`
export const NavigatorAnchor = styled(NavigationLink)`
  && {
    ${LinkStyle}
    ${PartnerWrap} & {
      font-size: ${typography.textThin} !important;
    }
  }
`
export const NavigationAnchor = styled(NavigationLink)`
  ${textEllipsis};
  && {
    ${LinkStyle}
    display: block;
    line-height: 2;
  }
  :hover {
    color: rgba(255, 255, 255, 0.7) !important;
  }
`
export const RecordRow = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  line-height: 27px;
`

export const PopoverText = styled.div`
  white-space: nowrap;
  font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
  color: #fff;
  text-align: ${props => (props.align ? props.align : 'left')};
`

export const WechatImg = styled.img`
  white-space: nowrap;
  width: 110px;
  height: 110px;
  margin: 0 5%;
  ${Media.lessThan(Media.small)} {
    width: 94px;
    height: 94px;
  }
`

export const Block = styled.div`
  display: flex;
  justify-content: space-between;
`
