import React from 'react'

import Partner from './partner'
import { Container } from './atoms'
import PostCards from './post-cards'
import OtherProduct from './other-product'

import Wrapper from '../wrapper'
import { Divider } from '../atoms'

class Footer extends React.PureComponent {
  render() {
    const { info, showFriendLink, friendLink } = this.props
    return (
      <Container>
        <Wrapper>
          <PostCards info={info} />
          <Divider style={{ backgroundColor: '#2d2e31' }} />
          <OtherProduct />
          <Divider style={{ backgroundColor: '#2d2e31' }} />
          <Partner showFriendLink={showFriendLink} friendLink={friendLink} />
        </Wrapper>
      </Container>
    )
  }
}

export default Footer
