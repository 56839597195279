import { darken } from 'polished'
import styled from 'styled-components'
import { hideSMDown, color, spacing, transition, Media } from '../../theme'

export const ArrowIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${p => (p.top ? p.top : '46%')};
  width: 70px;
  height: 70px;
  background: ${p => (p.arrowBg ? p.arrowBg : '')};
  border-radius: 50%;
  left: ${p => (p.arrowLeft ? p.arrowLeft : 'none')};
  right: ${p => (p.arrowRight ? p.arrowRight : 'none')};
  ::after {
    content: '';
    position: absolute;
    display: block;
    top: 28px;
    left: 28px;
    width: 12px;
    height: 12px;
    transform: rotate(-45deg);
    border-radius: 2px;
    border-right: solid 2px ${darken(0.2, '#fff')};
    border-bottom: solid 2px ${darken(0.2, '#fff')};
    transition: ${transition('border-color')};
  }
  :hover {
    background: ${p => (p.hoverArrowBg ? p.hoverArrowBg : '')};
    ::after {
      border-color: ${color.primary};
    }
  }
  ${hideSMDown};
`
export const SwipeNavWrap = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 26px;
  ${Media.lessThan(Media.small)} {
    bottom: ${p => (p.navBottom ? p.navBottom : '26px')};
  }
`
export const SwipeNav = styled.span`
  cursor: pointer;
  display: block;
  width: 60px;
  height: 4px;
  background-color: ${darken(0.4, '#fff')};
  transition: ${transition('background-color')};
  & + & {
    margin-left: ${spacing.base};
  }
  background-color: ${({ active, fadeNavBg }) => active && (fadeNavBg ? fadeNavBg : '#fff')};
  ${Media.lessThan(Media.small)} {
    width: 15px;
    height: 2px;
  }
`
export const Container = styled.div`
  position: relative;
  height: ${p => (p.containerHeight ? p.containerHeight : '480px')};
`
