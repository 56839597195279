import React from 'react'
import { MobileAccountContainer } from './atoms'
import { API } from '../../utils'
import Button from '../button'
import { onAuthEntry } from '../../service'
class MobileAccount extends React.PureComponent {
  render() {
    const { isExpand } = this.props
    return (
      <MobileAccountContainer isExpand={isExpand}>
        <Button as='a' className='login-btn' onClick={() => onAuthEntry(`${API.auth.host}/sso`)}>
          登录
        </Button>
        <Button
          as='a'
          className='register-btn'
          onClick={() => onAuthEntry(`${API.auth.host}/sso/register`)}
        >
          免费注册
        </Button>
      </MobileAccountContainer>
    )
  }
}
export default MobileAccount
