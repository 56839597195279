import React from 'react'
import { Row } from '../grid'
import {
  Title,
  DeusColumn,
  BlockAnchor,
  VerticalDivider,
  PostCardsContainer,
  NavigationAnchor,
  WechatImg,
  Follow,
  Deus,
  FollowColumn,
  Block,
  ContactColumn,
} from './atoms'
import wechatImg from '../concat-bar/images/wechat.png'

class PostCards extends React.PureComponent {
  render() {
    return (
      <PostCardsContainer>
        <Row>
          <DeusColumn lg='2' xs='3'>
            <Title>大雁云</Title>
            <Block>
              <div>
                <BlockAnchor href='/demos'>案例中心</BlockAnchor>
                <BlockAnchor href='/cooperate.html'>生态合作</BlockAnchor>
                <BlockAnchor href='/news'>新闻中心</BlockAnchor>
                <BlockAnchor href='/about.html'>关于我们</BlockAnchor>
              </div>
              <div>
                <BlockAnchor href='/download.html'>客户端下载</BlockAnchor>
                <NavigationAnchor to='/support/' target='_blank' isWithoutPrefix>
                  帮助中心
                </NavigationAnchor>
                <NavigationAnchor
                  to='/support/terms-of-service/'
                  target='_blank'
                  rel='nofollow'
                  isWithoutPrefix
                >
                  服务条款
                </NavigationAnchor>
              </div>
            </Block>
          </DeusColumn>
          <DeusColumn lg='1' xs='1'>
            <VerticalDivider />
          </DeusColumn>
          <ContactColumn className='contact' lg='5' xs='8'>
            <Title>联系我们</Title>
            <div>商务咨询： 440-880-8120</div>
            <div>
              电子邮箱：
              <BlockAnchor
                style={{ display: 'inline-block' }}
                href='mailto:liumingyan@rayvision.com'
                rel='noopener noreferrer'
              >
                liumingyan@rayvision.com
              </BlockAnchor>
            </div>
            <div>地址：深圳市南山区商华路2号阳光科创中心B座17层</div>
          </ContactColumn>
          <DeusColumn lg='1' xs='1'>
            <VerticalDivider />
          </DeusColumn>
          <Deus lg='1' xs='1'>
            <VerticalDivider />
          </Deus>
          <FollowColumn lg='2' xs='3'>
            <Follow>关注大雁云</Follow>
            <WechatImg src={wechatImg}></WechatImg>
            <div className='wechat-number'>大雁云微信公众号</div>
          </FollowColumn>
        </Row>
      </PostCardsContainer>
    )
  }
}

export default PostCards
