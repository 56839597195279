import React from 'react'
import Flex from '../flex'
import NavigationLink from './navigation-link'
import Button from '../button'
import MobileMenu from './moblie-menu'
import MobileAccount from './mobile-account'
import MobileUser from './mobile-user'
import { API, Axios } from '../../utils'
import { onAuthEntry } from '../../service'
import {
  Navbar,
  Brand,
  StyledWrapper,
  MenuIcon,
  CloseIcon,
  IconWrap,
  PCMenu,
  LoginWrapper,
  DropdownMenu,
  UserMsg,
  UserAvatar,
  DropdownWrap,
  DropdownItem,
  Account,
  IconAccount,
  IconUser,
  Arrow,
  DownloadBtn,
} from './atoms'
import withLocation from '../location/with-location'
import dayanLogo from '../../images/logo.png'
import iconUser from '../../../main/src/images/index/icon_my.png'

class Header extends React.PureComponent {
  static menuList = [
    {
      link: '/',
      text: '首页',
      alt: '大雁云',
    },
    {
      link: '/demos',
      text: '案例',
    },
    {
      link: '/price.html',
      text: '价格',
    },
    {
      link: '/news/',
      text: '新闻',
      target: '_blank',
      withoutPrefix: true,
    },
    {
      link: '/about.html',
      text: '关于我们',
    },
    {
      link: '/support/',
      text: '帮助中心',
      target: '_blank',
      withoutPrefix: true,
    },
  ]
  static taskList = [
    {
      link: '/user/profile',
      text: '个人中心',
    },
    {
      link: '/user/topUp',
      text: '立即充值',
    },
    {
      link: '/user/consumption',
      text: '消费清单',
    },
    {
      link: '/user/profile',
      text: '基本资料',
    },
  ]
  state = {
    showAccountMenu: false,
    showUserMenu: false,
    showMobileMenu: false,
    isTriggerLogout: false,
    userData: {},
    isShowMenu: false,
    isShowArrow: false,
  }

  handleMenuClick = () => {
    this.setState(preState => ({ showMobileMenu: !preState.showMobileMenu }))
  }

  handleAccountOpen = () => {
    this.setState(preState => ({ showAccountMenu: !preState.showAccountMenu }))
  }

  handleUserOpen = () => {
    this.setState(preState => ({ showUserMenu: !preState.showUserMenu }))
  }

  handleLogout = async () => {
    const instance = new Axios(API.auth.host)
    await instance
      .post(API.auth.logout, {
        raySyncUserKey: '',
      })
      .then(res => {
        if (res && res.code === 200) {
          this.setState({
            isTriggerLogout: true,
          })
        }
      })
  }

  queryUserData = async () => {
    const instance = new Axios(API.auth.host)
    instance.post(API.auth.queryUser).then(res => {
      if (res && res.code === 200) {
        this.setState({
          userData: res.data,
        })
      }
    })
  }

  redirectToAuth = async () => {
    const instance = new Axios(API.auth.host)
    await instance.post(API.auth.getAuthToken).then(res => {
      if (res && res.code === 200) {
        window.open(`https://account.dayancloud.com/authenticate?token=${res.data}&language=zh`)
      }
    })
  }

  handleMouseOver = () => {
    this.setState(preState => ({
      isShowMenu: !preState.isShowMenu,
      isShowArrow: !preState.isShowArrow,
    }))
  }

  componentDidMount() {
    this.queryUserData()
  }
  render() {
    const { location, Location, headerBg, isBorder } = this.props
    const { showAccountMenu, showUserMenu, showMobileMenu, isShowMenu, isShowArrow } = this.state
    const { userName } = this.state.userData
    const isUserLogin = !this.state.isTriggerLogout && userName
    const isUserLoginPhone = !this.state.isTriggerLogout && userName
    const currentMenu =
      Header.menuList.slice(1).find(l => {
        return Location.pathname.startsWith(l.link)
      }) || {}

    return (
      <Navbar headerBg={headerBg}>
        <StyledWrapper isBorder={isBorder}>
          <Flex>
            <Brand href='/'>
              <img src={dayanLogo} alt='' />
            </Brand>
            <PCMenu>
              {Header.menuList.map((l, i) => (
                <NavigationLink
                  key={i}
                  to={l.link}
                  title={l.alt || l.text}
                  isActive={`${location.pathname}${location.search}` === l.link}
                  target={l.target}
                  isWithoutPrefix={currentMenu.withoutPrefix}
                >
                  {l.text}
                </NavigationLink>
              ))}
            </PCMenu>
          </Flex>
          {isUserLogin ? (
            <DropdownMenu>
              <UserMsg>
                <UserAvatar>{userName && userName.substring(0, 1)}</UserAvatar>
                <div onClick={this.handleMouseOver}>
                  <span>{userName}</span>
                  <Arrow show={isShowArrow}></Arrow>
                </div>
                <Button
                  as='a'
                  className='download-btn'
                  onClick={() => onAuthEntry(`/download.html`)}
                  id=''
                >
                  下载客户端
                </Button>
              </UserMsg>
              <DropdownWrap show={isShowMenu}>
                {Header.taskList.map((item, index) => {
                  return (
                    <DropdownItem key={index}>
                      <a href={API.auth.host + item.link}>{item.text}</a>
                    </DropdownItem>
                  )
                })}
                <DropdownItem>
                  <span onClick={this.redirectToAuth}>实名认证</span>
                  {/* 暂保留authStatus */}
                  {/* <span className='level' onClick={this.redirectToAuth}>
                    {authStatus === 0
                      ? '未实名'
                      : authStatus === 6
                      ? '个人用户(已认证)'
                      : '企业用户(已认证)'}
                  </span> */}
                </DropdownItem>
                <DropdownItem>
                  <span onClick={this.handleLogout} className='logout'>
                    退出登录
                  </span>
                </DropdownItem>
              </DropdownWrap>
            </DropdownMenu>
          ) : (
            <LoginWrapper>
              <DownloadBtn
                onClick={() => onAuthEntry(`/download.html`)}
                id=''
                isActive={`${location.pathname}` === '/download.html'}
              >
                下载客户端
              </DownloadBtn>
              <Button
                as='a'
                className='wrapper-btn'
                onClick={() => onAuthEntry(`${API.auth.host}/sso`)}
                id='denglu'
                rel='nofollow'
              >
                登录
              </Button>
              <Button
                as='a'
                className='register-btn'
                onClick={() => onAuthEntry(`${API.auth.host}/sso/register`)}
                id='zhuce'
                rel='nofollow'
              >
                免费注册
              </Button>
            </LoginWrapper>
          )}
          <Flex className='show-at-mobile'>
            <Account>
              <IconAccount isActive={!isUserLoginPhone} onClick={this.handleAccountOpen}>
                <img src={iconUser} alt='' />
              </IconAccount>
              <IconUser isActive={isUserLoginPhone} onClick={this.handleUserOpen}>
                <span>{userName && userName.substring(0, 1)}</span>
              </IconUser>
            </Account>
            <IconWrap onClick={this.handleMenuClick}>
              <MenuIcon isActive={!showMobileMenu} />
              <CloseIcon isActive={showMobileMenu} />
            </IconWrap>
          </Flex>
        </StyledWrapper>
        <MobileMenu isExpand={showMobileMenu} location={location} />
        <MobileAccount isExpand={showAccountMenu} />
        <MobileUser isExpand={showUserMenu} userName={userName} />
      </Navbar>
    )
  }
}

export default withLocation(Header)
