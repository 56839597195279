import React, { Component, Fragment } from 'react'

class Item extends Component {
  render() {
    const { href, children } = this.props
    return <li>{href ? <a href={href}>{children}</a> : <Fragment>{children}</Fragment>}</li>
  }
}

export default Item
