import Img from 'gatsby-image'
import styled from 'styled-components'
import { Media } from '@dayancloud/common/theme'

const Billboard = styled(Img)`
  z-index: -1;
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  ${Media.lessThan(Media.small)} {
    left: auto;
  }
`

export default Billboard
