import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import Wrapper from '../wrapper'
import Media from '../../theme/media'
import { hideSMDown, hideSMUp, textEllipsis } from '../../theme/mixin'
import transition from '../../theme/transition'
import Flex from '../flex'
import { color, typography, spacing } from '../../theme/variables'
import downArrow from '../../images/downArrow.png'
import upArrow from '../../images/upArrow.png'
import moreIcon from '../../images/icon_more.png'
import closeIcon from '../../images/icon_close.png'

export const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  border: none;
  border-bottom: ${props => props.isBorder && '1px solid rgba(255,255,255,0.2)'};
  max-width: 100%;
  height: 100%;
  .show-at-mobile {
    ${hideSMUp};
  }
  ${Media.lessThan(Media.small)} {
    justify-content: space-between;
    max-width: 100%;
    .show-at-mobile {
      position: relative;
      z-index: 2;
      margin-right: 7px;
    }
  }
`
export const PCMenu = styled.div`
  ${hideSMDown};
`
export const LoginWrapper = styled(Flex)`
  .wrapper-btn {
    background-color: transparent;
    margin-right: 44px;
    font-size: ${typography.textSmall};
    color: #2f323e;
    padding: 0;
    cursor: pointer;
    font-weight: 500;
    :hover {
      color: #6b6cff;
    }
  }
  .register-btn {
    width: 128px;
    height: 62px;
    line-height: 62px;
    border-radius: 0;
    font-size: ${typography.textSmall};
    font-weight: 500;
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const DownloadBtn = styled.a`
  background-color: transparent;
  margin-right: 44px;
  font-size: ${typography.textSmall};
  color: ${props => (props.isActive ? '#6B6CFF' : '#2f323e')};
  padding: 0;
  font-weight: 500;
  cursor: pointer;
  :hover {
    color: #6b6cff;
  }
`

export const Navbar = styled.header`
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  height: 62px;
  line-height: 62px;
  min-width: 1200px;
  background: ${props => props.headerBg};
  transition: ${transition('background-color')};
  box-shadow: 0px 0px 20px 0px rgba(181, 181, 181, 0.1);
  ${Media.lessThan(Media.small)} {
    min-width: initial;
    height: 50px;
    line-height: 50px;
  }
`
export const Brand = styled.a`
  cursor: pointer;
  width: 138px;
  display: inline-block;
  margin: 0 36px 0 30px;
  img {
    width: 106px;
  }
  ${Media.lessThan(Media.small)} {
    width: 86px;
    margin: auto 0;
    img {
      width: 86px;
    }
  }
`
const commonLink = css`
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 0 22px;
  text-decoration: none;
  font-weight: 500;
  font-size: ${typography.textSmall};
  color: ${p => (p.isActive ? color.primary : '#2F323E')};
  transition: ${transition(['color', 'background-color'])};
  :hover {
    color: ${color.primary};
  }
`
export const NavAnchor = styled.a`
  ${commonLink}
`
export const NavLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  ${commonLink}
`
export const MenuIcon = styled.div`
  display: ${p => (p.isActive ? 'block' : 'none')};
  background-image: url(${moreIcon});
  background-size: 100% 100%;
  height: 21px;
  width: 21px;
`
export const CloseIcon = styled.div`
  display: ${p => (p.isActive ? 'block' : 'none')};
  background-image: url(${closeIcon});
  background-size: 100% 100%;
  height: 21px;
  width: 21px;
`
export const Account = styled.div`
  margin-right: 24px;
  margin-top: 3px;
`
export const IconAccount = styled.div`
  display: ${p => (p.isActive ? 'block' : 'none')};
  img {
    width: 17px;
  }
`

export const IconUser = styled.div`
  display: ${p => (p.isActive ? 'block' : 'none')};
  width: 21px;
  height: 21px;
  line-height: 18px;
  background: linear-gradient(-42deg, #afb0ff, #6b7cff);
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  text-align: center;
`

export const IconWrap = styled.div`
  ${hideSMUp};
  width: 16px;
  height: 16px;
  transition: ${transition('transform')};
`
export const MobileMenuContainer = styled(Flex)`
  flex-wrap: wrap;
  overflow: hidden;
  transition: ${transition('max-height')};
  height: ${props => (props.isExpand ? '100vh' : '0px')};
  max-width: 100%;
  flex-direction: column;
  ${hideSMUp};
  .content > a {
    text-align: left;
    font-size: ${typography.textThin};
    height: 54px;
    line-height: 54px;
    display: block;
    box-shadow: 0px 1px 0px 0px #e8e8e8;
    width: 100%;
    padding: 0 5px;
  }
  .content {
    background: #ffffff;
    height: 100%;
    width: 77%;
    padding-left: 15px;
  }
  ::before {
    content: '';
    width: 23%;
    height: 100vh;
    background: #000000;
    opacity: 0.4;
  }
`
export const AccountDetail = styled.div`
  display: flex;
  align-items: center;
  width: 240px;
  height: 70px;
  background: #ccc;
  ${Media.lessThan(Media.small)} {
    > a {
      padding: 0;
      ${textEllipsis}
    }
  }
`
export const UserAvatar = styled.div`
  background: linear-gradient(-42deg, #afb0ff, #6b7cff);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: white;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  margin-right: ${spacing.small};
  ${Media.lessThan(Media.small)} {
    width: 21px;
    height: 21px;
  }
`
export const DropdownMenu = styled.div`
  ${commonLink};
  padding: 0;
  position: relative;
`
export const UserMsg = styled.div`
  color: #000;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    color: ${color.primary};
  }
  span {
    color: #2f323e;
  }
  .download-btn {
    width: 128px;
    height: 62px;
    line-height: 62px;
    border-radius: 0;
    font-size: ${typography.textSmall};
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const Arrow = styled.div`
  width: 10px;
  height: 6px;
  background-image: url(${props => (props.show ? `${upArrow}` : `${downArrow}`)});
  display: inline-block;
  background-size: 100% 100%;
  margin: 0 20px 0 14px;
`

export const DropdownWrap = styled.div`
  position: absolute;
  left: 53%;
  top: 67px;
  text-align: center;
  transform: translateX(-100%);
  width: 164px;
  padding: 4px 3px;
  transition: ${transition(['display'])};
  display: ${props => (props.show ? `block` : 'none')};
  color: #646c7a;
  background-color: #ffffff;
  box-shadow: 0px 6px 18px 2px rgb(88 88 88 / 10%);
  border-radius: 4px;
`
export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  line-height: 34px;
  font-size: ${typography.textSmall};
  a {
    color: #646c7a;
  }
  a:hover {
    color: ${color.primary};
  }
  .name {
    color: #ffffff;
  }
  .level {
    color: ${color.primary};
  }
  .logout:hover {
    color: ${color.primary};
  }
  :hover {
    background: #efefff;
    border-radius: 4px;
  }
`
export const LoginWrapperButton = styled(Flex)`
  flex-direction: column;
  font-size: 18px;
  .contact-btn {
    width: 220px;
    height: 34px;
    line-height: 34px;
    margin-top: 25px;
    font-size: ${typography.textThin};
    img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }
`

export const MobileAccountContainer = styled.div`
  display: ${p => (p.isExpand ? 'flex' : 'none')};
  height: 104px;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 0 30px;
  .login-btn {
    width: 140px;
    height: 34px;
    background: #6b6cff;
    border-radius: 3px;
    line-height: 34px;
    font-size: ${typography.textThin};
    margin-right: 35px;
  }
  .register-btn {
    width: 140px;
    height: 34px;
    border: 1px solid #6b6cff;
    background: #fff;
    color: #6b6cff;
    border-radius: 3px;
    line-height: 32px;
    font-size: ${typography.textThin};
  }
`

export const MobileUserContainer = styled.div`
  display: ${p => (p.isExpand ? 'flex' : 'none')};
  height: 104px;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0 30px;
  span {
    font-size: 15px;
    font-weight: 500;
    color: #2f323e;
  }
  .logout-btn {
    width: 105px;
    height: 34px;
    border: 1px solid #6b6cff;
    background: #fff;
    color: #6b6cff;
    border-radius: 3px;
    line-height: 32px;
    font-size: ${typography.textThin};
  }
`
