import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import EasyPaginationButton from './easy-pagination-button'
import { Icon, IconType } from '../icon'
import Flex from '../flex'

const PaginationContainer = styled(Flex)`
  flex-wrap: wrap;
`

class Pagination extends Component {
  constructor(props) {
    super(props)
    this.maxChunk = 10 // 最多显示10个按钮
  }
  getPages() {
    const { total, currentPage } = this.props
    if (total <= 10) {
      return Array.from({ length: total }, (x, i) => i + 1)
    } else {
      if (currentPage > 8) {
        if (currentPage > total - 2) {
          return Array.from({ length: 6 }, (x, i) => i + total - 7)
        }
        return Array.from({ length: 6 }, (x, i) => i + currentPage - 5)
      } else {
        return Array.from({ length: 6 }, (x, i) => i + 3)
      }
    }
  }
  changePage = value => {
    this.setState({ currentPage: value })
    this.props.changePage && this.props.changePage(value)
  }

  render() {
    const { total, currentPage } = this.props
    this.pages = this.getPages()
    return (
      <PaginationContainer>
        <EasyPaginationButton
          type='previous'
          total={total}
          isBan={currentPage === 1}
          currentPage={currentPage}
          changePage={this.changePage}
        >
          <Icon type={IconType.ArrowLeft} />
        </EasyPaginationButton>
        {total <= this.maxChunk ? (
          <Fragment>
            {this.pages.map((number, index) => (
              <EasyPaginationButton
                key={index}
                total={total}
                currentPage={currentPage}
                changePage={this.changePage}
              >
                {number}
              </EasyPaginationButton>
            ))}
          </Fragment>
        ) : (
          <Fragment>
            <EasyPaginationButton total={total}>1</EasyPaginationButton>
            {currentPage > 8 ? (
              <EasyPaginationButton isBan={true}>...</EasyPaginationButton>
            ) : (
              <EasyPaginationButton total={total}>2</EasyPaginationButton>
            )}
            {this.pages.map((number, index) => (
              <EasyPaginationButton key={index} total={total}>
                {number}
              </EasyPaginationButton>
            ))}
            {currentPage < total - 2 ? (
              <EasyPaginationButton isBan={true}>...</EasyPaginationButton>
            ) : (
              <EasyPaginationButton total={total}>{total - 1}</EasyPaginationButton>
            )}
            <EasyPaginationButton total={total}>{total}</EasyPaginationButton>
          </Fragment>
        )}
        <EasyPaginationButton
          type='next'
          total={total}
          isBan={currentPage === total}
          currentPage={currentPage}
          changePage={this.changePage}
        >
          <Icon type={IconType.ArrowRight} />
        </EasyPaginationButton>
      </PaginationContainer>
    )
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  total: PropTypes.number,
  changePage: PropTypes.func,
}

export default Pagination
