import React from 'react'
import { MobileUserContainer } from './atoms'
import { API, Axios } from '../../utils'
import Button from '../button'

class MobileUser extends React.PureComponent {
  handleLogout = async () => {
    const instance = new Axios(API.auth.host)
    await instance.post(API.auth.logout, {
      raySyncUserKey: '',
    })
  }
  render() {
    const { isExpand, userName } = this.props
    return (
      <MobileUserContainer isExpand={isExpand}>
        <span>{userName}</span>
        <Button as='a' className='logout-btn' onClick={this.handleLogout}>
          退出
        </Button>
      </MobileUserContainer>
    )
  }
}
export default MobileUser
