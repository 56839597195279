import styled from 'styled-components'
import { lighten } from 'polished'
import Flex from '../flex'
import { H2, H3 } from '../atoms'
import {
  typography,
  color,
  multiLineTextEllipsis,
  textEllipsis,
  Media,
  transition,
  spacing,
} from '../../theme'

export const SideBarTitle = styled(H2)`
  padding: 0 ${spacing.small};
  margin: 1rem 0;
  border-left: 4px solid ${color.primary};
  font-size: ${typography.h4};
  color: ${lighten(0.2, color.panel)};
`
export const CmsPostContainer = styled.div`
  margin: 38px 0 55px;
  color: ${lighten(0.2, color.panel)};
  a {
    color: ${color.primary};
  }
  div,
  p,
  span {
    line-height: 2;
  }
  p,
  span,
  img,
  iframe {
    margin: ${spacing.base} 0;
  }
  img,
  video,
  iframe {
    display: block;
    max-width: 100%;
  }
  table {
    margin: 1em 0;
    width: 100%;
    overflow: hidden;
    border: 0;
  }
  tr {
    border: 1px solid ${color.text};
  }
  td {
    word-wrap: break-word;
    max-width: 7em;
    border: 1px solid ${color.highlight};
    padding: 5px;
  }
  th {
    border: 1px solid ${color.primary};
    background-color: ${color.secondary};
    color: #fff;
    padding: 1em;
  }
  ul,
  ol {
    padding-left: 20px;
  }
  *.center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  *.bold {
    font-weight: bold;
  }
  *.img-source {
    text-align: center;
    color: ${color.prompt};
    font-size: ${typography.textSmall};
    margin: 0;
  }
  pre,
  code {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  &.news img,
  &.share img {
    margin: ${spacing.base} auto;
  }
`
export const CmsPostTitle = styled(H3)`
  margin-top: 20px;
  color: ${color.panel};
  font-weight: 500;
`
export const CmsPostMsg = styled(Flex)`
  justify-content: flex-start;
  color: ${color.prompt};
  margin: 10px 0 20px;
  font-size: ${typography.textSmall};
  ${textEllipsis}
  span {
    margin-right: ${spacing.small};
  }
  svg {
    margin-right: 5px;
  }
  div {
    margin-right: ${spacing.base};
  }
`
export const CmsLinkToPost = styled.p`
  color: #666;
  font-size: ${typography.textSmall};
  margin-bottom: ${spacing.small};
  a {
    color: #666;
  }
  :hover,
  :hover a {
    color: ${color.primary};
  }
`
export const PostCardContainer = styled(Flex)`
  width: 790px;
  position: relative;
  margin-bottom: 40px;
  justify-content: flex-start;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: ${transition('box-shadow')};
  &:hover {
    box-shadow: 0px 0px 12px 0px #edecff;
  }
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
    width: 100%;
  }
`

export const PostCardImg = styled.div`
  img {
    display: block;
    width: 280px;
    height: 160px;
    object-fit: cover;
    overflow: hidden;
  }
  ${Media.lessThan(Media.small)} {
    img {
      width: 100%;
    }
  }
`
export const PostCardContent = styled(Flex)`
  max-width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding: 22px 20px 15px 25px;
  ${Media.lessThan(Media.small)} {
    padding: 10px 0;
  }
`
export const PostCardTitle = styled.div`
  font-size: 20px;
  a {
    color: #182940;
    font-size: 20px;
  }
  &:hover {
    a {
      color: ${color.primary};
    }
  }
`
export const PostCardIntro = styled.div`
  font-size: ${typography.textSmall};
  color: ${color.text};
  margin: 1rem 0;
  ${multiLineTextEllipsis(2)}
  p {
    margin: 0;
  }
  strong {
    color: ${color.primary};
  }
  em {
    color: ${color.primary};
    font-style: normal;
  }
  pre,
  code {
    overflow: auto;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
`
export const PostCardDate = styled(Flex)`
  max-width: 100%;
  justify-content: flex-start;
  font-size: ${typography.textSmall};
  color: #8a939e;
`
