import styled from 'styled-components'
import { color, typography } from '../theme'
import transition from '../theme/transition'
import Media from '../theme/media'

export const H1 = styled.h1`
  margin: 0;
  color: white;
  font-size: ${typography.h1};
`
export const H2 = styled.h2`
  margin: 0;
  color: white;
  font-size: ${typography.h2};
`
export const H3 = styled.h3`
  margin: 0;
  color: white;
  font-size: ${typography.h3};
`
export const H4 = styled.h4`
  margin: 0;
  color: white;
  font-size: ${typography.h4};
`
export const Paragraph = styled.p`
  color: ${color.text};
  font-size: ${typography.text};
`
export const Prompt = styled.span`
  color: ${color.prompt};
  font-size: ${typography.textSmall};
`
export const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: ${color.divider};
`
export const Anchor = styled.a`
  cursor: pointer;
  color: currentColor;
  text-decoration: none;
  transition: ${transition('color')};
  font-size: ${typography.textThin} !important;
  :hover {
    color: rgba(255, 255, 255, 0.7);
  }
`
export const Allow = styled.div`
  color: currentColor;
  text-decoration: none;
  transition: ${transition('color')};
  margin-left: 20px;
  display: inline;
  font-size: ${typography.textThin};
  ${Media.lessThan(Media.small)} {
    display: block;
    margin-left: 0;
    font-size: 11px;
    line-height: 17px;
  }
`
export const Section = styled.section`
  padding: 100px 0;
  ${Media.lessThan(Media.small)} {
    padding: 40px 0;
  }
`
export const LightSection = styled(Section)`
  background-color: ${color.panel};
`
export const SectionTitle = styled.div`
  text-align: center;
  font-size: ${typography.title};
  margin-bottom: ${p => (p.marginBottom ? `${p.marginBottom}px` : '60px')};
  color: ${props => props.color || '#000000'};
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${p => (p.marginBottomMobile ? `${p.marginBottomMobile}px` : '30px')};
    font-weight: 500;
    font-size: 20px; /* fuck design */
  }
`
