import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { CmsContentService } from '../../service'

import {
  PostCardContainer,
  PostCardImg,
  PostCardContent,
  PostCardTitle,
  PostCardIntro,
  PostCardDate,
} from './atoms'

class CmsPostCard extends React.PureComponent {
  render() {
    const { title, slug, content, date } = this.props
    const coverImgSrc = CmsContentService.getCoverImg(content)
    const intro = CmsContentService.getAbstract(content)
    return (
      <PostCardContainer>
        <PostCardImg>
          <img src={coverImgSrc} alt={title} />
        </PostCardImg>
        <PostCardContent>
          <PostCardTitle>
            <Link to={`/${slug}/`} target='_blank'>
              {title}
            </Link>
          </PostCardTitle>
          <PostCardIntro>{intro}</PostCardIntro>
          <PostCardDate>{date}</PostCardDate>
        </PostCardContent>
      </PostCardContainer>
    )
  }
}

CmsPostCard.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
}

export default CmsPostCard
