import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '../button'

class PaginationButton extends Component {
  handleClick = (type, total, children) => {
    const { isBan } = this.props
    if (isBan) {
      return
    }
    let currentPageNum = this.props.currentPage
    if (type === 'previous') {
      if (currentPageNum > 1) {
        currentPageNum = currentPageNum - 1
      } else {
        return
      }
    } else if (type === 'next') {
      if (currentPageNum + 1 <= total) {
        currentPageNum = currentPageNum + 1
      } else {
        return
      }
    } else {
      currentPageNum = children
    }
    this.props.changePage && this.props.changePage(currentPageNum)
  }

  render() {
    const { type, total, isBan, children, currentPage } = this.props
    return (
      <Button
        variant='pagination'
        active={Number(children) === currentPage && type === 'number'}
        isBan={isBan}
        onClick={this.handleClick.bind(this, type, total, children)}
      >
        {children}
      </Button>
    )
  }
}
PaginationButton.propTypes = {
  type: PropTypes.oneOf(['number', 'previous', 'next']),
  total: PropTypes.number,
  currentPage: PropTypes.number,
  children: PropTypes.node.isRequired,
  changePage: PropTypes.func,
}
PaginationButton.defaultProps = {
  type: 'number',
  active: false,
  isBan: false,
}
export default PaginationButton
