import React from 'react'
import { Anchor, Allow } from '../atoms'
import { PartnerWrap, FriendLinkWrap, RecordRow } from './atoms'

class Partner extends React.PureComponent {
  render() {
    const { showFriendLink, friendLink } = this.props
    return (
      <PartnerWrap direction='column' halign='start'>
        <RecordRow>
          <div>
            © 2021
            <Anchor href='https://www.rayvision.com' rel='nofollow'>
              深圳市瑞云科技股份有限公司{' '}
            </Anchor>
            <Anchor
              target='_blank'
              href='https://beian.miit.gov.cn/#/Integrated/index'
              rel='nofollow'
            >
              粤ICP备12028569号
            </Anchor>
            <Allow>中华人民共和国增值电信业务经营许可证编号：合字B1-20200125</Allow>
          </div>
        </RecordRow>
        {showFriendLink ? (
          <>
            <FriendLinkWrap>
              <div>友情链接：友情链接申请，百度权重&gt;=1，请加QQ：3586783172</div>
              {friendLink &&
                friendLink.edges.map(({ node }, index) => (
                  <a key={index} href={node.link} target='_blank' rel='noopener noreferrer'>
                    {node.title}
                  </a>
                ))}
            </FriendLinkWrap>
          </>
        ) : (
          ''
        )}
      </PartnerWrap>
    )
  }
}

Partner.defaultProps = {
  showFriendLink: false,
}

export default Partner
