const contentfulCoverImgPattern = '!\\[.*\\]\\((.*?)\\)'

export default class CmsContentService {
  static getCoverImg(content) {
    const databaseCoverImgPattern = '<img[\\s\\S]*?src="(.*?)"'
    const imgCoverRegex = new RegExp(`(${contentfulCoverImgPattern}|${databaseCoverImgPattern})`)
    const matchResult = content.match(imgCoverRegex)
    if (matchResult) {
      return matchResult[2] || matchResult[3]
    }
    return ''
  }
  static removeCoverImg(content) {
    const databaseCoverImgPattern = '<img[\\s\\S]*?>'
    const imgCoverRegex = new RegExp(`(${contentfulCoverImgPattern}|${databaseCoverImgPattern})`)
    const replaceResult = content.replace(imgCoverRegex, '')
    return replaceResult || ''
  }
  static getAbstract(content) {
    const text = CmsContentService.getMainText(content)
    return text.replace(/(\r|\n|__|#|<.*?>|!?\[.*\]\(.*?\)!?)/g, '')
  }

  static getMainText(content) {
    const contentfulTextPattern = '!\\[.*\\)([\\s\\S\\r\\n]*)'
    const databaseTextPattern = '<img.*\\/>([\\s\\S\\r\\n]*)'
    const textRegex = new RegExp(`(${contentfulTextPattern}|${databaseTextPattern})`)
    const matchResult = content.match(textRegex)
    if (matchResult) {
      return matchResult[0]
    }
    return content
  }
}
