import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={25} {...props}>
    <path
      fillRule='evenodd'
      opacity={0.6}
      d='M5.384 18.44c-.469 0-.939-.871-.939-.5 0-1.321.47-1.796.939-1.796.47 0 .939.475.939 1.796 0-.371-.469.5-.939.5m2.817-7.059c-4.225 0-7.512 3.339-7.512 6.016 0 1.899 1.095 3.983 2.817 4.749.313.158-.626 2.058-.313 2.216a.115.115 0 0 0 .055.012c.206 0 .702.136 1.197-.645.496-.322.991.121 1.197.121.022 0 .04.03.055.11.783-.706 1.565-.548 2.504-.548 4.226 0 7.513-2.691 7.513-6.015 0-2.677-3.287-6.016-7.513-6.016'
    />
    <path
      fillRule='evenodd'
      d='M23.787 8.702c0-4.042-4.518-8.317-9.994-8.317C8.318.385 3.8 4.66 3.8 8.702c0 4.635 4.518 8.478 9.993 8.478 1.096 0 2.191-.298 3.149-.571.411-.136 3.968 1.909 3.559 1.773.989-.137-1.232-2.591-.821-2.863 2.464-1.636 4.107-4.091 4.107-6.817ZM9.726 8.601c-.216 0-1.35-.623-1.35-1.245 0-.622 1.134-1.245 1.35-1.245.676 0 2.044.623 2.044 1.245 0 .622-1.368 1.245-2.044 1.245Zm8.646 0c-.676 0-1.162-.623-1.162-1.245 0-.622.486-1.245 1.162-1.245 1.098 0 1.351.623 1.351 1.245 0 .622-.253 1.245-1.351 1.245Z'
    />
  </svg>
)

export default SvgComponent
