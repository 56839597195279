import styled from 'styled-components'
import Media from '../theme/media'

const Wrapper = styled.div`
  max-width: 1200px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  ${Media.lessThan(Media.small)} {
    padding: 0 1rem;
  }
`

export default Wrapper
