import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={25} height={25} {...props}>
    <path
      fillRule='evenodd'
      d='M23.917 15.504h-1.094c-.521 4.647-4.236 8.338-8.893 8.823-.025.005-.051.006-.076.011l-.022.002a2.646 2.646 0 0 1-.44.044h-1.005a2.6 2.6 0 0 1-2.597-2.597 2.6 2.6 0 0 1 2.597-2.598h1.005a2.599 2.599 0 0 1 2.595 2.571c2.878-1.213 4.903-4.062 4.903-7.376v-4c0-4.411-3.589-8-8-8s-8 3.589-8 8v5.12H1.864c-.538 0-.974.236-.974-.96v-4.32c0-.53.436-.96.974-.96h1.093C3.516 4.276 7.756.384 12.89.384c5.36 0 9.374 3.892 9.933 8.88h1.094c.537 0 .973.43.973.96v4.32c0 1.196-.436.96-.973.96Zm-9.927 6.283a.599.599 0 0 0-.598-.598h-1.005c.193 0-.597.268-.597.598 0 .329.79.597.597.597h.503c.226 0 .448-.015.669-.034.247-.073.431.21.431-.563Z'
    />
    <path
      fillRule='evenodd'
      d='M15.557 9.859c-.737 0-1.334-.556-1.334-1.242 0-.686.597-1.242 1.334-1.242.736 0 1.333.556 1.333 1.242 0 .686-.597 1.242-1.333 1.242Zm-1.005 2.92c.106-.408.552-.654.981-.56.436.099.705.508.6.914-.336 1.301-1.768 2.246-3.403 2.246-.78 0-3.066-.945-3.404-2.245-.106-.407.162-.816.599-.914.435-.097.876.151.981.558.764.619.945 1.085 1.824 1.085.878 0 1.662-.466 1.822-1.084Zm-4.329-2.92c-.736 0-1.333-.556-1.333-1.242 0-.686.597-1.242 1.333-1.242.737 0 1.334.556 1.334 1.242 0 .686-.597 1.242-1.334 1.242Z'
    />
  </svg>
)

export default SvgComponent
