import styled from 'styled-components'
import { lighten } from 'polished'

import Flex from '../flex'
import { color, typography, textEllipsis } from '../../theme'

export const BreadcrumbContainer = styled(Flex.withComponent('ul'))`
  width: 100%;
  color: ${lighten(0.4, color.panel)};
  ${textEllipsis}
  list-style: none;
  li {
    font-size: ${typography.textSmall};
    a {
      border: 10px solid transparent;
      border-left: 0px;
      color: ${color.primary};
    }
    ::after {
      content: ${props => `"${props.separator}"`};
      padding: 0 8px;
    }
    :last-child::after {
      content: '';
    }
  }
`
