import React from 'react'
import NavigationLink from './navigation-link'
import { MobileMenuContainer, LoginWrapperButton } from './atoms'
import WithLocation from '../location/with-location.jsx'
import Button from '../button'
import phoneIcon from '@dayancloud/main/src/images/index/icon_phone_white.png'
class MobileMenu extends React.PureComponent {
  static mobileMenuList = [
    {
      link: '/',
      text: '首页',
      alt: '大雁云',
    },
    {
      link: '/demos',
      text: '案例',
    },
    {
      link: '/price.html',
      text: '价格',
    },
    {
      link: '/news',
      text: '新闻',
      withoutPrefix: true,
    },
    {
      link: '/about.html',
      text: '关于我们',
    },
    {
      link: '/support',
      text: '帮助中心',
      withoutPrefix: true,
    },
    {
      link: '/download.html',
      text: '下载客户端',
    },
  ]
  render() {
    const { location, isExpand, Location } = this.props
    const currentMenu =
      MobileMenu.mobileMenuList.slice(1).find(l => {
        return Location.pathname.startsWith(l.link)
      }) || {}
    return (
      <MobileMenuContainer isExpand={isExpand}>
        <div className='content'>
          {MobileMenu.mobileMenuList.map((l, i) => (
            <NavigationLink
              key={i}
              to={l.link}
              title={l.alt || l.text}
              target={l.target}
              isActive={location.pathname === l.link}
              isWithoutPrefix={currentMenu.withoutPrefix}
            >
              {l.text}
            </NavigationLink>
          ))}
          <LoginWrapperButton>
            <Button as='a' className='contact-btn'>
              <img src={phoneIcon} alt='' />
              400-880-8120
            </Button>
          </LoginWrapperButton>
        </div>
      </MobileMenuContainer>
    )
  }
}
export default WithLocation(MobileMenu)
