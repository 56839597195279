import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

const SEO = ({ title, keywords, description, shareImgSrc, ogUrl, ogType }) => (
  <Helmet title={title}>
    <meta name='keywords' content={keywords} />
    <meta name='description' content={description} />
    <meta data-react-helmet='true' property='og:image' content={shareImgSrc} />
    <meta data-react-helmet='true' property='og:url' content={ogUrl} />
    <meta data-react-helmet='true' property='og:title' content={title} />
    <meta data-react-helmet='true' property='og:description' content={description} />
    <meta data-react-helmet='true' property='og:type' content={ogType} />
  </Helmet>
)

SEO.propTypes = {
  title: PropTypes.string,
  keywords: PropTypes.string,
  description: PropTypes.string,
  shareImgSrc: PropTypes.string,
  ogUrl: PropTypes.string,
  ogDescription: PropTypes.string,
  ogType: PropTypes.string,
}

SEO.defaultProps = {
  title: '',
  keywords: '',
  description: '',
  shareImgSrc: 'https://www.dayancloud.com/ogImg.png',
  ogUrl: 'https://www.dayancloud.com',
  ogType: '',
}

export default SEO
