import styled from 'styled-components'

import { color } from '../../theme/variables'
import transition from '../../theme/transition'
import { hideSMDown } from '../../theme/mixin'

export const ContactbarContainer = styled.div`
  width: 60px;
  height: 260px;
  cursor: pointer;
  position: fixed;
  z-index: 10;
  bottom: 24px;
  right: 24px;
  padding: 10px 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  ${hideSMDown};
`

export const PopoverWrapper = styled.div`
  display: none;
  position: absolute;
  top: 15px;
  left: -15px;
  transform: translate(-100%, -50%);
  padding: 16px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(220, 220, 229, 0.4);
`

export const WechatWrapper = styled.div`
  display: none;
  position: absolute;
  top: 15px;
  left: -15px;
  transform: translate(-100%, -50%);
  padding: 12px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
`

export const ContactItem = styled.li`
  width: 40px;
  height: 40px;
  margin: 5px 10px;
  padding-top: 8px;
  list-style: none;
  position: relative;
  svg {
    width: 22px;
    height: 22px;
    display: block;
    margin: auto;
    color: #2f323e;
    transition: ${transition('color')};
    &:hover {
      color: ${color.primary};
    }
  }
  :hover {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    background: #f6f7fd;
    > svg {
      color: ${color.primary};
    }
    img {
      opacity: 1;
    }

    ${PopoverWrapper} {
      display: block;
    }

    ${WechatWrapper} {
      display: block;
    }
  }
`

export const PopoverText = styled.div`
  white-space: nowrap;
  font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
  color: #2f323e;
  text-align: ${props => (props.align ? props.align : 'left')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '500')};
`

export const WechatImg = styled.img`
  width: 110px;
  height: 110px;
`
