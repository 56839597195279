class API {
  static auth = {
    host: 'https://task.dayancloud.com',
    downloadHost: 'https://cdl.renderbus.com',
    windowsVersion: '/client/desktop/dycloud/changelog.php?p=3&os=windows&lang=0',
    newWindowsVersion: '/client/desktop/dycloud/changelog.php?p=5&os=windows&lang=0',
    queryUser: '/api/rendering/user/queryUser',
    logout: '/api/rendering/user/userLogout',
    getAuthToken: '/api/rendering/user/generateRealNameAuth',
    isPhoneRegister: '/api/sso/register/registerCheck',
  }
}
export default API
