import Axios from 'axios'
import { navigate } from 'gatsby'
import { LocationService } from '.'
import { SearchQuery } from '../constant/search-query'

class ESearch {
  static pageSize = 8
  static axios = Axios.create({
    withCredentials: false,
    baseURL: '/',
  })

  static updateUrl(query, currentPackage = 'news') {
    const searchParams = {
      query,
      page: 1,
    }
    const currentLocationUrl = LocationService.updateLocationSearchString(searchParams, '/search')
    navigate(currentLocationUrl, { state: { newPage: 1 } })
  }

  static async search(
    index = 'dayancloud-news',
    pageNum = 1,
    searchFields = [],
    keyword = '',
    highlightFields = [], // 需要高亮的属性
    partialHighlightFields = [], // 只显示部分高亮内容的属性
  ) {
    const result = await ESearch.axios.post('/graphql/dayancloud/', {
      query: `#graphql
        query(
          $index: String!,
          $pageNum: Int!,
          $searchFields: [String]!,
          $keyword: String!,
          $pageSize: Int,
          $highlightFields: [String],
          $partialHighlightFields: [String]) {
          search(input: {
            index: $index,
            pageNum: $pageNum,
            keyword: $keyword,
            pageSize: $pageSize,
            searchFields: $searchFields,
            highlightFields: $highlightFields,
            partialHighlightFields: $partialHighlightFields})
          {
            ${SearchQuery.get(index)}
          }
      }`,
      variables: {
        index,
        pageNum,
        searchFields,
        keyword,
        highlightFields,
        partialHighlightFields,
        pageSize: ESearch.pageSize,
      },
    })
    return result
  }
}

export default ESearch
