import React, { Fragment } from 'react'
import Helmet from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import GlobalStyle from '../theme/global-style'
import Contactbar from './concat-bar/concat-bar'

class Layout extends React.PureComponent {
  render() {
    const {
      children,
      location,
      topFiveNews,
      showFriendLink,
      friendLink,
      showFooter = true,
      headerBg = '#ffffff',
      isBorder = false,
    } = this.props
    return (
      <Fragment>
        <Helmet>
          <script>
            {`
              var _hmt = _hmt || [];
              (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?d273098dd956ba2bc1ca435ecf59748c";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
              })();
            `}
          </script>
        </Helmet>
        <Header location={location} headerBg={headerBg} isBorder={isBorder} />
        <main>{children}</main>
        <Contactbar />
        <GlobalStyle></GlobalStyle>
        {showFooter ? (
          <Footer
            info={topFiveNews}
            showFriendLink={showFriendLink}
            friendLink={friendLink}
            showFooter={showFooter}
          />
        ) : (
          ''
        )}
      </Fragment>
    )
  }
}

export default Layout
