import React from 'react'
import { Title, ProductLinkWrap } from './atoms'

class OtherProduct extends React.PureComponent {
  static otherLinks = [
    { text: 'Renderbus瑞云渲染', link: 'https://www.renderbus.com/' },
    { text: '3DCAT实时渲染云', link: 'https://www.3dcat.live/' },
    { text: '青椒云云电脑', link: 'https://www.qingjiaocloud.com/' },
    { text: '镭速传输', link: 'https://www.raysync.cn/' },
  ]
  render() {
    return (
      <ProductLinkWrap>
        <Title className='productTitle'>瑞云旗下其他产品系列：</Title>
        {OtherProduct.otherLinks.map(({ text, link }, index) => (
          <a key={index} href={link} right='45px' rel='nofollow'>
            {text}
          </a>
        ))}
      </ProductLinkWrap>
    )
  }
}

export default OtherProduct
