export const typography = Object.freeze({
  h1: '3rem', // NOTE: 48px on desktop
  h2: '1.875rem', // NOTE: 30px on desktop
  h3: '1.5rem', // NOTE: 24px on desktop
  h4: '1.125rem', // NOTE: 18px on desktop
  title: '2.5rem', // NOTE: 40px on desktop
  text: '1rem', // NOTE: 16px on desktop
  textSmall: '0.875rem', // NOTE: 14px on desktop
  textThin: '0.75rem', // NOTE: 12px on desktop
})
export const color = Object.freeze({
  primary: '#6B6CFF',
  secondary: '#1cc300',
  text: '#7D829C',
  prompt: '#6B6CFF',
  divider: '#ddd',
  panel: '#333333',
  background: '#F2F2F6',
  input: '#333333',
  highlight: '#6B6CFF',
  vermilion: '#ef6147',
  carrot: '#f38b29',
  white: '#fff',
})
export const spacing = Object.freeze({
  small: '10px',
  base: '20px',
  large: '30px',
})
