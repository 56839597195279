import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Wrapper from '../wrapper'
import { typography, Media } from '../../theme'
import Button from '../button'
import Toast from '../toast'
import { sendEmail } from '../../service/email'

const SubmitFormContainer = styled.div`
  background: #0d0c17;
  width: 100%;
  margin: 0 auto;
  padding: 100px 0 64px 0;
  ${Media.lessThan(Media.small)} {
    padding: 53px 20px 20px;
  }
`
const SubmitFormWrap = styled(Wrapper)`
  background: #1a1925;
  width: 100%;
  box-shadow: 0px 0px 16px 4px rgba(20, 7, 0, 0.2);
  border-radius: 20px;
  padding: 40px 128px 45px 70px;
  .title {
    font-size: ${typography.h2};
    font-weight: 500;
    color: #ffffff;
  }
  button {
    width: 140px;
    height: 40px;
    line-height: 40px;
    font-size: ${typography.text};
    font-weight: 500;
    color: #ffffff;
  }
  .first-row {
    margin: 20px 0 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .input {
    outline-style: none;
    border: 2px solid #5b5b66;
    border-radius: 5px;
    padding: 15px;
    color: #fff;
    font-size: ${typography.textSmall};
    &:focus {
      border-color: #ccc;
    }
  }
  .info {
    width: 24%;
    height: 42px;
  }
  .textarea {
    width: 100%;
    height: 82px;
    margin-bottom: 20px;
    resize: none;
  }
  ${Media.lessThan(Media.small)} {
    border-radius: 10px;
    padding: 31px 0 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .title {
      font-size: ${typography.h3};
      padding-left: 20px;
    }
    form {
      padding: 20px 26px 0 20px;
    }
    button {
      margin: 0 auto;
      width: 100%;
      height: 40px;
      line-height: 1;
      font-size: ${typography.h4};
    }
    .first-row {
      margin: 0;
      flex-wrap: wrap;
    }
    .input {
      border: 1px solid #5b5b66;
      padding: 10px;
    }
    .info {
      width: 100%;
      margin: 0 0 10px;
      font-size: 15px;
    }
    .textarea {
      margin-bottom: 20px;
      font-size: 15px;
    }
  }
`

class SubmitForm extends PureComponent {
  state = {
    name: '',
    phone: '',
    content: '',
    wechat: '',
    qq: '',
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    const { name, phone, content, wechat, qq } = this.state

    if (name === '' || phone === '' || content === '') {
      Toast.info('为了方便客服联系您，请您填写完整并确保填写信息的真实性！')
      return false
    }
    if (!/^1[0-9]{10}$/.test(phone)) {
      Toast.info('请输入正确的电话号码')
      return false
    }

    const subject = '【大雁云】服务咨询'
    const target = 'liumingyan@rayvision.com'
    const formString = `
    <h3>【大雁云】服务咨询</h3>
    <p>姓名: ${name}</p>
    <p>手机号: ${phone}</p>
    <p>微信号：${wechat}</p>
    <p>QQ号：${qq}</p>
    <p>咨询内容：${content}</p>
    `
    sendEmail(target, subject, formString).then(() => {
      Toast.info('消息已发送，稍后联系您！')
      this.setState({
        name: '',
        phone: '',
        content: '',
        wechat: '',
        qq: '',
      })
    })
  }

  render() {
    const { name, phone, content, wechat, qq } = this.state
    return (
      <SubmitFormContainer id={'submit-form'}>
        <SubmitFormWrap>
          <div className='title'>联系我们</div>
          <form onSubmit={this.handleSubmit} autoComplete='off'>
            <div className='first-row'>
              <input
                type='text'
                name='name'
                value={name}
                onChange={this.handleInputChange}
                className='input info'
                placeholder='姓名'
              />
              <input
                type='text'
                name='phone'
                value={phone}
                onChange={this.handleInputChange}
                className='input info'
                placeholder='手机号'
              />
              <input
                type='text'
                name='wechat'
                value={wechat}
                onChange={this.handleInputChange}
                className='input info'
                placeholder='微信号（选填）'
              />
              <input
                type='text'
                name='qq'
                value={qq}
                onChange={this.handleInputChange}
                className='input info'
                placeholder='QQ号（选填）'
              />
            </div>
            <textarea
              className='input textarea'
              name='content'
              onChange={this.handleInputChange}
              placeholder='如果你想对产品了解更多，请留下联系方式，我们会尽快联系您'
              value={content}
            />
            <Button type='submit' id='tj-footer'>
              提交
            </Button>
          </form>
        </SubmitFormWrap>
      </SubmitFormContainer>
    )
  }
}

export default SubmitForm
