import React, { Component } from 'react'
// import IconQQ from '../icon/qq'
import IconHotline from '../icon/hotline'
import IconEmail from '../icon/email'
import IconWechat from '../icon/wechat'
import IconBackToTop from '../icon/back-to-top'
import IconKefu from '../icon/kefu'
import IconwWchatkefu from '../icon/wechatkefu'
import {
  ContactbarContainer,
  ContactItem,
  PopoverText,
  PopoverWrapper,
  WechatWrapper,
  WechatImg,
} from './atoms'
import wechatImg from './images/wechat.png'
import { navigate } from 'gatsby'

class Contactbar extends Component {
  backToTop = () => {
    document.body.scrollIntoView({ behavior: 'smooth' })
  }

  toSubmitForm = () => {
    navigate('/#submit-form')
  }

  render() {
    return (
      <ContactbarContainer>
        {/* <ContactItem>
          <a
            rel='noreferrer'
            href='https://wpa1.qq.com/WmURmKNB?_type=wpa&qidian=true'
            target='_blank'
          >
            <IconQQ style={{ width: '18px' }} />
          </a>
        </ContactItem> */}
        <ContactItem>
          <a
            href='https://wpa1.qq.com/SV5t0IWb?_type=wpa&qidian=true'
            rel='noreferrer'
            target='_blank'
          >
            <IconKefu style={{ width: '26px', height: '25px' }} />
          </a>
          <PopoverWrapper>
            <PopoverText fontSize='14px' style={{ color: '#646C7A' }}>
              在线咨询
            </PopoverText>
          </PopoverWrapper>
        </ContactItem>
        <ContactItem>
          <a
            href='https://wpa1.qq.com/ujoglPGq?_type=wpa&qidian=true'
            rel='noreferrer'
            target='_blank'
          >
            <IconwWchatkefu style={{ width: '24px', height: '24px' }} />
          </a>
          <PopoverWrapper>
            <PopoverText fontSize='14px' style={{ color: '#646C7A' }}>
              微信咨询
            </PopoverText>
          </PopoverWrapper>
        </ContactItem>
        <ContactItem>
          <IconHotline style={{ width: '20px' }} />
          <PopoverWrapper>
            <PopoverText>24小时全国服务热线： </PopoverText>
            <PopoverText fontSize='18px' fontWeight='700'>
              400-880-8120
            </PopoverText>
          </PopoverWrapper>
        </ContactItem>
        <ContactItem onClick={this.toSubmitForm}>
          <IconEmail style={{ width: '20px' }} />
          <PopoverWrapper>
            <PopoverText>跳转联系我们，发送邮箱：</PopoverText>
            <PopoverText fontSize='16px'>liumingyan@rayvision.com</PopoverText>
          </PopoverWrapper>
        </ContactItem>
        <ContactItem>
          <IconWechat style={{ width: '20px' }} />
          <WechatWrapper>
            <WechatImg src={wechatImg}></WechatImg>
            <PopoverText fontSize='12px' align='center'>
              大雁云官方公众号
            </PopoverText>
          </WechatWrapper>
        </ContactItem>
        <ContactItem onClick={this.backToTop}>
          <IconBackToTop style={{ width: '16px' }} />
        </ContactItem>
      </ContactbarContainer>
    )
  }
}
export default Contactbar
