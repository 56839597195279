import axios from 'axios'

function getCookie(name) {
  const parts = document.cookie.split(/;\s*/)
  for (let i = 0; i < parts.length; i++) {
    let partMap = parts[i].split('=')
    if (partMap[0] === name) {
      return partMap[1]
    }
  }
  return ''
}

class Axios {
  constructor(host) {
    this.host = host
    this.instance = axios.create({
      headers: {
        'Content-Type': 'application/json',
        version: '1.0.0',
        signature: 'rayvision2017',
        channel: 9,
        platform: getCookie('platform') || 54,
        userKey: getCookie('rayvision_userKey'),
      },
      withCredentials: true,
      baseURL: process.env.NODE_ENV === 'development' ? '/' : host,
    })
  }
  post(url, params = {}) {
    return this.instance
      .post(url, params)
      .then(res => {
        return res.data
      })
      .catch(error => {
        console.log(error.message)
      })
  }
}

export default Axios
