import React from 'react'
import PropTypes from 'prop-types'
import { range } from '../../utils'
import FadeSwiperViews from './fade-swiper-views'
import autoPlay from './auto-play.js'
import { FadeNavWrap, FadeNav, Container } from './atoms'
import { ArrowIcon } from '../swiper/atoms'
const AutoPlaySwipeableViews = autoPlay(FadeSwiperViews)
const interval = 5000

class FadeSwiper extends React.PureComponent {
  state = { index: 0, auto: true }
  handleChangeIndex = index => {
    this.setState({ index })
  }
  handleFocus = () => {
    this.setState({ auto: false })
  }
  handleBlur = () => {
    this.setState({ auto: true })
  }
  handleNextClick = () => {
    const length = this.props.children.length
    this.setState(prev => ({
      index: prev.index + 1 === length ? 0 : prev.index + 1,
    }))
  }
  handlePrevClick = () => {
    const length = this.props.children.length
    this.setState(prev => ({
      index: prev.index === 0 ? length - 1 : prev.index - 1,
    }))
  }
  render() {
    const { index, auto } = this.state
    const {
      children,
      left,
      marginLeft,
      top,
      arrowBg,
      hoverArrowBg,
      arrowLeft,
      arrowRight,
      fadeNavBg,
      containerHeight,
      navBottom,
      swiperHeight,
      width,
      ...rest
    } = this.props
    return (
      <Container
        {...rest}
        height={swiperHeight}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        containerHeight={containerHeight}
        width={width}
      >
        <AutoPlaySwipeableViews
          index={index}
          interval={interval}
          autoplay={auto}
          enableMouseEvents
          onChangeIndex={this.handleChangeIndex}
          left={left}
          marginLeft={marginLeft}
        >
          {children}
        </AutoPlaySwipeableViews>
        <FadeNavWrap bottom={navBottom}>
          {range(children.length).map(i => (
            <FadeNav
              interval={interval}
              id='fadeNavItem'
              key={i}
              active={i === index}
              onMouseEnter={this.handleChangeIndex.bind(this, i)}
              fadeNavBg={fadeNavBg}
            ></FadeNav>
          ))}
        </FadeNavWrap>
        <ArrowIcon
          onClick={this.handlePrevClick}
          style={{ transform: 'rotate(180deg)' }}
          top={top}
          arrowBg={arrowBg}
          arrowLeft={arrowLeft}
        />
        <ArrowIcon
          onClick={this.handleNextClick}
          top={top}
          arrowBg={arrowBg}
          hoverArrowBg={hoverArrowBg}
          arrowRight={arrowRight}
        />
      </Container>
    )
  }
}

FadeSwiper.propTypes = {
  swiperHeight: PropTypes.string,
}
FadeSwiper.defaultProps = {
  swiperHeight: '100vh',
}

export default FadeSwiper
