import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Icon, IconType } from '../../icon'

import {
  SearchContainer,
  SupportSearchContainer,
  SupportInstantSearchContainer,
  SearchInput,
  SearchButton,
  SupportSearchButton,
  SupportInstantSearchButton,
  SupportInstantSearchButton as QaInstantSearchButton,
  QaSearchContainer,
} from './atoms'

class SearchBox extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.state = {
      query: '',
    }
  }

  handleChange(e) {
    const { onChange } = this.props
    this.setState({
      query: e.target.value,
    })
    if (onChange) {
      onChange(e.target.value)
    }
  }

  handleSearch(e) {
    const { onSearch } = this.props
    if (e.type === 'keypress' && e.key === 'Enter') {
      onSearch(e.target.value)
    } else if (e.type === 'click') {
      onSearch(this.state.query)
    }
  }
  render() {
    const { type } = this.props
    const ContainerTag = {
      news: SearchContainer,
      support: SupportSearchContainer,
      'support-instant': SupportInstantSearchContainer,
      qa: QaSearchContainer,
    }[type]
    const ButtonFragment = {
      news: <SearchButton onClick={this.handleSearch}>搜索</SearchButton>,
      support: (
        <SupportSearchButton onClick={this.handleSearch}>
          <Icon type={IconType.Search} />
        </SupportSearchButton>
      ),
      'support-instant': (
        <SupportInstantSearchButton onClick={this.handleSearch}>
          <Icon type={IconType.Search} />
        </SupportInstantSearchButton>
      ),
      qa: (
        <QaInstantSearchButton onClick={this.handleSearch}>
          <Icon type={IconType.Search} />
        </QaInstantSearchButton>
      ),
    }[type]
    return (
      <ContainerTag>
        <SearchInput
          type='text'
          value={this.state.query}
          placeholder={this.props.searchContent || '请输入搜索关键词'}
          onChange={this.handleChange}
          onKeyPress={this.handleSearch}
        />
        {ButtonFragment}
      </ContainerTag>
    )
  }
}

SearchBox.propTypes = {
  searchContent: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  type: PropTypes.oneOf(['news', 'support', 'support-instant', 'qa']).isRequired,
}

export default SearchBox
