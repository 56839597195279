import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import Button from '../button'
import { LocationService } from '../../service'
import { PaginationType } from './pagination-type'
import { TargetTypeContext } from './pagination'

class PaginationButton extends Component {
  handleClick = (type, total, children, context) => {
    const { isBan } = this.props
    if (isBan) {
      return
    }
    let currentPageNum = 1
    if (context === PaginationType.Static) {
      currentPageNum = parseInt(LocationService.getPageNum())
    } else {
      currentPageNum = parseInt(LocationService.getLocationSearchParams().get('page')) || 1
    }
    let newPage = currentPageNum
    if (type === 'previous') {
      if (currentPageNum > 1) {
        newPage = currentPageNum - 1
      } else {
        return
      }
    } else if (type === 'next') {
      if (currentPageNum + 1 <= total) {
        newPage = currentPageNum + 1
      } else {
        return
      }
    } else {
      newPage = children
    }
    if (context === PaginationType.Static) {
      navigate(LocationService.updatePageNum(newPage), { state: { newPage } })
    } else {
      const currentLocationUrl = LocationService.updateLocationSearchString({ page: newPage })
      navigate(currentLocationUrl, { state: { newPage } })
    }
  }

  render() {
    const { type, total, children, active, isBan } = this.props
    return (
      <TargetTypeContext.Consumer>
        {context => (
          <Button
            variant='pagination'
            active={active}
            isBan={isBan}
            onClick={this.handleClick.bind(this, type, total, children, context)}
          >
            {children}
          </Button>
        )}
      </TargetTypeContext.Consumer>
    )
  }
}
PaginationButton.propTypes = {
  type: PropTypes.oneOf(['number', 'previous', 'next']),
  total: PropTypes.number,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  isBan: PropTypes.bool,
}
PaginationButton.defaultProps = {
  type: 'number',
  active: false,
  isBan: false,
}
export default PaginationButton
