import axios from 'axios'
import { API } from '../utils'

export const verifyCodeType = Object.freeze({
  text: 1,
  sound: 2,
})

export function uploadFile(file) {
  return axios({
    baseURL: API.auth.host,
    url: '/api/dayan/user/agent/uploadFile',
    method: 'POST',
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function sendVerifyCode(phone, type) {
  return axios({
    baseURL: API.auth.host,
    url: '/api/dayan/user/agent/sendVerifyCode',
    method: 'POST',
    data: { phone, type },
  })
}

export function verifyPhone(phone) {
  return axios({
    baseURL: API.auth.host,
    url: '/api/dayan/user/agent/verifyPhone',
    method: 'POST',
    data: { phone },
  })
}

export function agentApply(
  contactName,
  phone,
  verificationCode,
  email,
  companyName,
  licURL,
  detailMessage,
) {
  return axios({
    baseURL: API.auth.host,
    url: '/api/dayan/user/agent/agentApply',
    method: 'POST',
    data: {
      businessType: 2,
      contactName,
      phone,
      verificationCode,
      email,
      companyName,
      licURL,
      detailMessage,
    },
  })
}
