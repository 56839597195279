import React from 'react'

import { NavAnchor, NavLink } from './atoms'

class NavigationLink extends React.PureComponent {
  render() {
    const { children, target, isActive, to, isWithoutPrefix, ...rest } = this.props
    return isWithoutPrefix ? (
      <NavAnchor href={to} isActive={isActive} {...rest}>
        {children}
      </NavAnchor>
    ) : (
      <NavLink to={to} isActive={isActive} {...rest}>
        {children}
      </NavLink>
    )
  }
}

export default NavigationLink
