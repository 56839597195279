import styled, { css } from 'styled-components'
import { color, transition, typography, spacing, Media } from '../../theme'
import { lighten } from 'polished'

const { primary, panel } = color

const ButtonBase = css`
  cursor: pointer;
  text-decoration: none;
  font-size: ${typography.h4};
  height: 44px;
  line-height: 44px;
  padding: 0 1.6em;
  position: relative;
  display: inline-block;
  text-align: center;
  touch-action: manipulation;
  white-space: nowrap;
  user-select: none;
  border-radius: 6px;
  background-color: ${primary};
  pointer-events: ${p => p.disabled && 'none !important'};
  transition: ${transition(['background-color', 'color', 'box-shadow'])};
  :active,
  :focus {
    outline: 0;
    background-color: #5051fc;
  }
  :not([disabled]):active {
    box-shadow: none;
  }
  :disabled {
    pointer-events: none;
    background-color: #abacff;
    color: #dadaff;
  }
  :hover {
    background-color: #8384ff;
    color: #fff;
  }
`
export const ContainedButton = styled.button`
  ${ButtonBase};
  color: #fff;
  border: 0 solid transparent;
  background-color: ${primary};
  border-radius: 3px;
`
export const OutlinedButton = styled.button`
  ${ButtonBase};
  background-color: #fff;
  border: 1px solid #8384ff;
  color: ${primary};
  font-weight: 500;
  :hover {
    background-color: #c0c1fd;
  }
`
const PaginationStatus = ({ isBan, active }) => css`
  cursor: ${isBan ? 'not-allowed' : 'pointer'};
  color: ${active ? 'white' : panel};
  border: 0;
  background-color: ${active ? primary : 'transparent'};
  @media (any-hover: hover) {
    :hover {
      color: ${isBan ? panel : 'white'};
      border: 1px solid ${isBan ? lighten(0.6, panel) : primary};
      background-color: ${isBan ? 'transparent' : primary};
      svg {
        color: ${isBan ? panel : 'white'};
      }
    }
  }
`
export const PaginationButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin: ${spacing.small};
  text-align: center;
  ${PaginationStatus}
  margin: 5px;
  transition: ${transition(['color', 'border', 'background-color'])};
  svg {
    color: ${panel};
  }
  :active,
  :focus {
    outline: 0;
  }
  user-select: none;
  ${Media.lessThan(Media.small)} {
    width: 25px;
    height: 25px;
    padding: 0;
  }
`
