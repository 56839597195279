export const SearchQuery = new Map([
  [
    'dayancloud-news',
    `#graphql
    total
    datas {
      data {
        createdAt
        title
        slug
        content
        plainContent
        tags {
          slug
          name
        }
      }
      highlight {
        title
        plainContent
      }
    }
  `,
  ],
])
